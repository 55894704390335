import axios from 'axios'
export default {
  methods: {
    rec_area_asesor (pAreaId) {
      const urlroute = this.$store.getters.getUrl + 'asesores_area/' + pAreaId
      this.items_correos = []
      this.loadingArea = true
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.items_correos = response.data
        if (pAreaId === 5) {
          for (let index = 0; index < this.items_correos.length; index++) {
            const element = this.items_correos[index]
            if (element.id_usuario === 42568) {
              this.items_correos = []
              this.items_correos.push(element)
              break
            }
          }
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingArea = false
      })
    }

  }
}
